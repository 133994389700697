import React from 'react'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">Tron Token Development Company</h1>           
            <p className="sub-heading">As a Premier Tron Token Development Company, Coinsclone offers reliable token development services on the Tron blockchain.</p>
            <p className="sub-small">We are the best fit for creating Tron based tokens with the most delicate Tron features in the blockchain industry.</p>
          </div>
          <div className="quick text-center">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/tron/tron-token-developments.png" alt="Tron token development services Banner" />
          </div>
          <p className="pharagraph">Partner with our expert Tron token developers.</p>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Create Tron Token</a>
        </div>
      </section>
    )
  }
}

export default BannerSection