import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0">
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2">Tron Token Development - <span className="bluecolor">An Overview</span></h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph text-center" >Tron token development involves creating and deploying custom tokens on the Tron blockchain, leveraging its high throughput, scalability, and smart contract capabilities to facilitate decentralized applications and financial services. This process includes token creation, smart contract programming, and integration with Tron’s ecosystem for seamless transactions and interactions.
              </p>
              <p className="pharagraph text-center mb-0">The Tron blockchain supports two major token standards for creation: TRC10 and TRC20. You can choose either standard based on your business needs and concepts. By selecting our premium Tron token development service, you can create and deploy high-performing tokens on the secure Tron blockchain with advanced features. Our team of skilled Tron token developers will manage your token project from ideation to deployment.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis